<template>
  <div style="padding: 0 10px;">
    <img src="@/image/bg_05.png" class="bgImg" alt="">
    <div class="LP_box0">
      <!-- 当前价格 -->
      <div class="LP_title">{{$t('Miner.title1')}}:</div>
      <div class="flex_lr">
        <div class="LP_box1 LP_text" style="width:49%;padding: 12px;">{{nTypeName}}：{{mt_price}}U</div>
        <div class="LP_box1 LP_text" style="width:49%;padding: 12px;">ULME：{{ulm_price}}U</div>
      </div>
    </div>
    <!-- 农耕矿池 -->
    <div class="LP_box0">
      <div class="LP_title">MT{{$t('Miner.title2')}}:</div>
      <div class="LP_box1 flex_ud" style="position: relative;z-index: 2;height:120px;">
        <img src="@/image/bg_04.png" style="position:absolute;top:0;left:0;bottom:0;right:0;margin:auto;z-index: -1;width: 100%;height: 100%;opacity: 0.6;" alt="">
        <div class="flex_lr">
          <!-- <div>全网社区算力：</div> -->
          <div class="LP_text">{{$t('Miner.text2')}}</div>
          <div class="LP_num">{{info.allTeamPower}}</div>
        </div>

      </div>
    </div>
    <!-- 我的等级 -->
    <div class="LP_box0 flex_lr" style="padding: 0 10px;">
      <div class="LP_title">{{$t('Miner.title3')}}</div>
      <div class="LP_title flex_row" >
        <div :style="level==0?'color:#00FFEA;':level==1?'color:#258B54;':level==2?'color:#B31426;':level==3?'color:#CFBD78;':''">V{{level}}({{info.RecommendedValue}}%)</div>
        <img @click="openLevelInfo" :src="require('@/image/vip/V' + level+'.png')" style="width:30px;height:30px;margin-left:10px;" alt="">
      </div>
    </div>
    <div class="LP_box0">
      <div class="LP_title">{{$t('Miner.title4')}}</div>
      <div class="LP_box1">
        <div class="LP_text flex_lr">
          <!-- <div>个人算力：</div> -->
          <div>{{$t('Miner.text3')}}</div>
          <div class="LP_num">{{toFixedWei(info.power390/2.8)||0}}</div>
        </div>
        <div class="LP_text flex_lr">
          <!-- <div>全网算力：</div> -->
          <div >{{$t('Miner.text4')}}</div>
          <div class="LP_num">{{info.powerTeam}}</div>
        </div>
        <div class="LP_text flex_lr">
          <div>布道算力:</div>
          <div class="LP_num" v-if="user._userTrendsPower_390==undefined||user._userTrendsPower_390==0">0</div>
          <div class="LP_num" v-else>{{user._userTrendsPower_390}}</div>
          </div>
      </div>
    </div>
    <div class="LP_box0">
       <!-- 我的算力 -->
      <div class="flex_lr">
        <div class="LP_title">我的收益:</div>
        <!-- <div class="LP_title">{{$t('Miner.title5')}}</div> -->
      </div>

      <div class="LP_box1">
              <div class="LP_title flex_lr" style="padding-bottom: 0;">
        <div class="" style="font-size:0.8rem;">布道收益:</div>
          <div class="LP_num2 flex_row">
            <div v-if="user._userTrendsPower_390!=undefined&&user._userTrendsPower_390>0">
              <div>布道收益</div>
              <div class="LP_num">可领取≈${{info.takeTrends}}</div>
            </div>
            <div class="shifang hand" v-if="info.takeTrends>0" @click="getTake(2)" style="background:#13b717;">{{$t('Miner.text10')}}</div>
            <div class="shifang" :style="'background-color:'+(user._userTrendsTime_390==0?'#bb5a82':'rgb(65 177 45)')" v-if="user._userTrendsTime_390!=undefined&&user._userTrendsPower_390>0" >{{user._userTrendsTime_390>0?'释放中':'未释放'}}</div>
          </div>
        </div>
        <div class="LP_title flex_lr" style="padding-bottom: 0;">
          <div class="" style="font-size:0.8rem;">{{$t('Miner.text5')}}</div>
          <div class="LP_num2 flex_row">
            <div >{{info.takeUserProfit?(info.takeUserProfit+'MT≈$'+Math.ceil(Number(info.takeUserProfit)*mt_price)):0}}</div>
            <!-- <div class="LP_btn hand" @click="getTake" style="background:#13b717;padding: 2px;margin: 2px;">{{$t('Miner.text10')}}</div> -->

          </div>
        </div>
        <div class="LP_title flex_lr" style="padding-bottom: 0;">
          <div class="" style="font-size:0.8rem;">剩余收益:</div>
          <div class="LP_num2 flex_row">
            <div style="margin:0 3px;">≈${{info.power390}}</div>
          </div>
        </div>
        <div style="margin: 10px;">
          <div class="flex_row" v-for="(item,index) in minerList" :key="index" style="color:#aba8a8;margin-top:20px;">
            <div class="pledge_num flex_center">{{index+1}}</div>
            <div style="width:0;flex:1;">
              <!-- 进度条 -->
              <div style="padding: 0 20px;">
                <div class="pledge pledge_box2 pledge_title flex_row" style="padding: 5px 0px;position: relative;">
                  <div class="y_center pledge_li" :style="'left:calc('+100/280*100+'% - 3px)'">
                    <div style="font-size: 12px;">100%</div>
                    <div style="height:10px;border-left:1px solid #f4f4f5;"></div>
                  </div>
                  <div class="y_center pledge_li" :style="'left:calc('+100/280*200+'% - 3px)'">
                    <div style="font-size: 12px;">200%</div>
                    <div style="height:10px;border-left:1px solid #f4f4f5;"></div>
                  </div>
                  <div class="y_center pledge_li" :style="'left:calc('+100/280*280+'% - 3px)'">
                    <div style="font-size: 12px;">280%</div>
                    <div style="height:10px;border-left:1px solid #f4f4f5;"></div>
                  </div>
                  <div class="pledge_jdt"><p :style="{width:item.start*100/280+'%',background:item.start>280?'rgb(211 168 32);':'#13b717'}" /></div>
                </div>
              </div>
              <div class="pledge pledge_box2 pledge_title flex_lr" style="padding: 5px 20px;color:#ffffff;font-size:0.8rem;">
                <div>已产收益:{{(item.start>280?280:item.start).toFixed(0)}}%</div>
                <div class="" style="font-size:0.8rem;">算力:{{item.num*390}}</div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      <!-- 领取 -->
      <div class="LP_btn hand" @click="getTake(1)" style="background:#13b717;">农耕收益领取</div>
    </div>
    <!-- 我的农耕 -->
    <div class="LP_box0">
      <div class="LP_title">{{$t('Miner.title6')}}</div>
      <div class="LP_box1">
        <div class="LP_box2 flex_lr">
          <div>
            <!-- 合约地址 -->
            <div class="LP_text flex_row">
              <div style="width:4.5rem;">{{nTypeName}}{{$t('Miner.text11')}}</div>
              {{mt_addressToken.slice(0,5)+'...'+mt_addressToken.slice(mt_addressToken.length-4)}}
              <i class="ifont icone-fuzhi LP_num" @click="copy(mt_addressToken)" />
            </div>
            <div class="LP_text flex_row">
              <div style="width:4.5rem;">ULME{{$t('Miner.text11')}}</div>
              {{ulm_addressToken.slice(0,5)+'...'+ulm_addressToken.slice(ulm_addressToken.length-4)}}
              <i class="ifont icone-fuzhi LP_num" @click="copy(ulm_addressToken)" />
            </div>
          </div>
          <!-- 去交易 -->
          <div class="toPay flex_row" @click="openUrl()">
            <img src="@/image/jinshan_18.png" alt="">
            <div>{{$t('Miner.text12')}}</div>
          </div>
        </div>
        <!-- 授权按钮 -->
        <div class="flex_lr">
          <!-- <div :class="approve_mt>100?'LP_btn_after':'LP_btn2'"  style="width:0;flex:1;padding: 10px;margin:0 5px;" @click="approveMt">{{$t('Miner.text13')}}{{nTypeName}}</div> -->
          <!-- <div :class="approve_ulm>100?'LP_btn_after':'LP_btn2'"  style="width:0;flex:1;padding: 10px;margin:0 5px;" @click="approveUlm">{{$t('Miner.text13')}}ULME</div> -->
          <!-- <div :class="approve_usdt>100?'LP_btn_after':'LP_btn2'"   style="width:0;flex:1;padding: 10px;margin:0 5px;" @click="approveUsdt">{{$t('Miner.text13')}}USDT</div> -->
        </div>
        <!-- 余额 -->
        <div class="LP_box2" style="margin: 10px 0 0;">
          <div class="flex_lr">
            <div class="LP_text">USDT{{$t('Miner.text14')}}：</div>
            <div class="LP_num2 flex_row">{{balance.usdt}}USDT</div>
          </div>
          <div class="flex_lr">
            <div class="LP_text">{{nTypeName}}{{$t('Miner.text14')}}：</div>
            <div class="LP_num2 flex_row">{{balance.mt}}{{nTypeName}}</div>
          </div>
          <div class="flex_lr">
            <div class="LP_text">ULME{{$t('Miner.text14')}}：</div>
            <div class="LP_num2 flex_row">{{balance.ulm}}ULME</div>
          </div>
        </div>
        <!-- 农耕 -->
        <!-- <div class="LP_btn hand" @click="addPop1=true" style="background:#13b717;margin: 10px 0 0;padding: 10px;">MT{{$t('Miner.text15')}}</div> -->
        <div class="LP_btn hand" style="background:#13b717;margin: 10px 0 0;padding: 10px;">MT{{$t('Miner.text15')}}</div>
      </div>
    </div>
    <div class="LP_box0">
      <div class="LP_title">{{$t('Miner.title8')}}:</div>
      <!-- <div class="LP_btn" style="background: #0C2379;padding: 10px;margin: 0;color:#00FFEA;">{{nTypeName}}-USDT LP</div> -->
      <div class="LP_box1">
        <div class="LP_text flex_lr">
          <div>{{$t('Miner.text16')}}</div>
          <div class="LP_num">{{user._regenerate_power_390}}USDT</div>
        </div>
        <!-- 已领取总收益 //2022-11-04↓ -->
        <!-- <div class="LP_text flex_lr">
          <div>{{$t('Miner.text17')}}</div>
          <div class="LP_num">{{user.take_power}}USDT</div>
        </div> -->
      </div>
    </div>
    <!-- 提币弹窗 -->
    <div class="take flex_center" v-if="addPop" @touchmove.prevent @mousewheel.prevent>
      <div class="mask" @click="addPop=0"></div>
      <div class="take_bg" :style="$setWidth=='100%'?'max-width: 90%;':'max-width: 500px;'">
        <div class="take_box y_center">
          <div style="color: #000000;font-weight: bold;text-align: center;font-family: simhei;">{{$t('Miner.text36')}}</div>
          <div class="take_text">当前可领取{{addPop==1?'静态收益':'动态收益'}}:{{addPop==1?info.takeUserProfit:info.takeTrendsCount}}MT</div>
          <div class="take_text" style="margin: 0;" v-if="addPop==2">提币后布道算力会减少{{info.takeTrends}}T</div>
          <div class="take_text" style="margin: 0;" v-if="addPop==2">(账户地址持币量≥布道收益领取量的{{take_limit_t}}%)</div>
          <div class="take_text" style="margin: 0;" v-if="addPop==1&&take_limit>0">(账户地址持币量≥静态收益领取量的{{take_limit}}%)</div>
          <div class="flex_lr" style="width:100%;">
            <div class="take_btn1 flex_center" @click="takePending">{{$t('Miner.text22')}}</div>
            <div class="take_btn2 flex_center" @click="addPop=0">{{$t('Miner.text23')}}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 增加弹窗 -->
    <div class="bind flex_center" style="z-index:999999999999" v-if="addPop1" @touchmove.prevent @mousewheel.prevent>
      <div class="mask" @click="addPop1=false"></div>
      <div class="bind_bg" :style="$setWidth=='100%'?'max-width: 90%;':'max-width: 500px;'">
        <div class="bind_box y_center">
          <div style="color: #000000;font-weight: bold;text-align: center;font-family: simhei;">{{$t('Miner.text15')}}</div>
          <div class="bind_text" style="margin: 10px;width: 100%;">
            <div class="y_center">
              <div style="font-size:1.1rem;">{{390*buyNumber}}T{{$t('Miner.text24')}}需要{{toFixedWei(290*buyNumber/mt_price,6)}}MT+{{toFixedWei(100*buyNumber/ulm_price,6)}}ULME</div>
            </div>
            <!-- <div class="flex_pj">
              <div class="y_center" style="background:#ffffff;padding:10px 0;width:0;flex:1;margin:10px 5px;">
                <div style="color: #1A99A4;margin-bottom:3px;">{{$t('Miner.text25')}}{{nTypeName}}</div>
                <div style="color: #ff0000;">{{info.minerMoneyMt||0}}{{nTypeName}}</div>
              </div>
              <div class="y_center" style="background:#ffffff;padding:10px 0;width:0;flex:1;margin:10px 5px;">
                <div style="color: #1A99A4;margin-bottom:3px;">{{$t('Miner.text25')}}ULME</div>
                <div style="color: #ff0000;">{{info.minerMoneyUlm||0}}ULME</div>
              </div>
            </div> -->
            <!-- <div>{{info.minerMoneyMt+'MT + '+info.minerMoneyUlm+'ULM'}}</div> -->
            <!-- <div style="color:#666;" v-if="info.minerMoneyUsdt>0">{{$t('Miner.text26')}} {{info.minerMoneyUsdt*1.1+'USDT'}}</div> -->
            <input type="number" v-model="buyNumber"  placeholder="请输入数量">
          <div :class="approve_mt>=290*buyNumber?'LP_btn_after':'LP_btn2'" v-if="approve_mt<290*buyNumber"  style="flex:1;padding: 10px;margin:5px;" @click="approveMt">{{$t('Miner.text13')}}MT</div>
          <div :class="approve_ulm>=100*buyNumber?'LP_btn_after':'LP_btn2'" v-if="approve_ulm<100*buyNumber"  style="flex:1;padding: 10px;margin:5px;" @click="approveUlm">{{$t('Miner.text13')}}ULME</div>

          </div>
          <input type="text" v-if="isSetting" v-model="buyAccount"  placeholder="请输入地址">
          <div v-if="isSetting" style="font-size: 13px;color: #e0e0e0;">为此地址添加农耕(如此地址无推荐人,您将成为推荐人)</div>
          <div class="bind_text" style="width:100%;margin:10px 0;">
          
            <!-- <div class="flex_lr">{{$t('Miner.text27')}} <i class="ifont icone-shezhi-xianxing" style="margin-left: 6px;" @click="isSetting=!isSetting"/></div> -->
            <!-- <div>USDT{{$t('Miner.text28')}}：{{balance.usdt}}USDT</div> -->
            <div>{{nTypeName}}{{$t('Miner.text28')}}：{{balance.mt}}{{nTypeName}}</div>
            <div>ULME{{$t('Miner.text28')}}：{{balance.ulm}}ULME</div>
          </div>
          <button @click="addLpFun1">{{$t('Miner.text15')}}</button>
        </div>
      </div>
    </div>
    <!-- 手动升级 -->
    <div class="bind flex_center" v-if="addPop2" @touchmove.prevent @mousewheel.prevent>
      <div class="mask" ></div>
      <div class="bind_bg" :style="$setWidth=='100%'?'max-width: 90%;':'max-width: 500px;'">
        <div class="bind_box y_center">
          <div class="bind_text" style="margin: 10px">
            <div>{{$t('Miner.text37')}}</div>
          </div>
          <button @click="updataLevel">{{$t('Miner.text38')}}</button>
        </div>
      </div>
          
      <div style="height: 50px;"></div>
    </div>
    <!-- 手动升级 -->
    <!-- 修正日期弹窗 -->
    <div class="bind flex_center" v-if="addPop6" @touchmove.prevent @mousewheel.prevent>
      <div class="mask" style="background: rgba(0,0,0,0.6);backdrop-filter: blur(3px);"></div>
      <div class="bind_bg" :style="$setWidth=='100%'?'max-width: 90%;':'max-width: 500px;'">
        <div class="bind_box y_center">
          <div class="bind_text" style="margin: 10px">
            <div>您的设置有错,请更正</div>
          </div>
          <button @click="changeTimeClick">更正</button>
        </div>
      </div>
          
      <div style="height: 50px;"></div>
    </div>
    <!-- 修正日期 -->
    <!-- 更改布道算力,算力超过应得 -->
    <div class="bind flex_center" v-if="addPop7" @touchmove.prevent @mousewheel.prevent>
      <div class="mask" style="background: rgba(0,0,0,0.6);backdrop-filter: blur(3px);"></div>
      <div class="bind_bg" :style="$setWidth=='100%'?'max-width: 90%;':'max-width: 500px;'">
        <div class="bind_box y_center">
          <div class="bind_text" style="margin: 10px">
            <div>您的剩余收益值已经低于布道算力值,布道收益已停止!进行农耕活动可激活布道收益.</div>
          </div>
          <button style="margin: 5px;" @click="checkTrends">确定</button>
          <button style="margin: 5px;" @click="addPop1=true">农耕</button>
        </div>
      </div>
          
      <div style="height: 50px;"></div>
    </div>
    <!-- end -->
    <!-- 手动加排行榜 -->
    <div class="bind flex_center" v-if="addPop4" @touchmove.prevent @mousewheel.prevent>
      <div class="mask" @click="addPop4=false"></div>
      <div class="bind_bg" :style="$setWidth=='100%'?'max-width: 90%;':'max-width: 500px;'">
        <div class="bind_box y_center">
          <div class="bind_text" style="margin: 10px">
            <div>您的社区算力有资格进入V{{level}}排行榜,是否PK一下</div>
          </div>
          <button @click="updataRanking">PK一下</button>
        </div>
      </div>
    </div>
    <!-- 手动加排行榜 -->
    <!-- 大额提现 -->
    <div class="bind flex_center" v-if="addPop5" @touchmove.prevent @mousewheel.prevent>
      <div class="mask" @click="addPop5=false"></div>
      <div class="bind_bg" :style="$setWidth=='100%'?'max-width: 90%;':'max-width: 500px;'">
        <div class="bind_box y_center">
          <div class="bind_text" style="margin: 10px">
            <div>您此次交易检测异常,需要将您的地址及提取金额以邮件形式发送至mtdao777@gmail.com,我们将会在24小时内邮件回复您,请留意您的邮箱</div>
          </div>
          <button @click="addPop5=false">我知道了</button>
        </div>
      </div>
    </div>
    <!-- 大额提现end -->
    <up-level-data v-if="addPop3" @close="addPop3=false" :info="info" :user="user" :level="level" :NFT="NFT"/>
    <!-- 手动加排行榜 -->
    <!-- 公告弹窗 -->
    <information v-if="show_notice" @close="show_notice=false" :head="'公告'" :title="notice.title" :content="notice.content" />
  </div>
</template>
 
<script>
import walletHelper from "@/utils/walletHelper.js"
import UpLevelData from "@/components/UpLevelData.vue"

import { Loading } from 'element-ui';

export default {
  components: {
    UpLevelData,
  },
  data () {
    return {
      nav:0,
      // 添加弹窗
      addPop:0,
      isSetting:false,
      buyAccount:'',
      addPop1:false,
      addPop2:false,
      addPop3:false,
      addPop4:false,
      addPop5:false,
      addPop6:false,
      addPop7:false,
      tableData:[
        
      ],
      pending:{},
      info:{},

      user:{},
      is_date:false,
      DEBUG:false,
      date_list:[],
      minerList:[],
      allPowerArr:[],
      chose_date:0,
      approve_mt:0,
      take_limit_t:50,
      take_limit:0,
      approve_ulm:0,
      approve_usdt:0,
      addLp:"",
      addLp1:"",
      NFT:"1",
      nType:"mt",
      nTypeName:"MT",
      pair:undefined,
      addressToken:'',
      isLoading:false,
      current_page:0,
      blance_ulm:0,
      level:0,
      last_page:0,
      buyNumber:1,//购买数量
      is_next:false,
      loading:0,
      thread:0,
      takeArray:[],
      // dataIndex:{
      //   _regenerate_power:"1",//再生(USDT)
      //   _take_power:"2",//所有已领取
      //   _power:"3",
      //   _powerTeam:"4",//团
      //   _powerTeamMax:"5",//团
      //   _userLevel:"6",
      //   _powerReceived:"7",//已领
      //   _minerCount:"8",
      //   _parentReward:"9",//布道奖励(USDT)
      //   _userLastTime:"10",
      // },
      // mt合约地址
      mt_addressToken:'',
      // ulm合约地址
      ulm_addressToken:'',
      // mt价格
      mt_price:0,
      // ulm价格
      ulm_price:0,
      changeTime:1672675200,
      // 余额
      balance:{
        usdt:0,
        mt:0,
        ulm:0
      },
      // 最新公告
      notice:{},
      show_notice:false
    }
  },
  computed: {
    	userInfo() {
			return this.$store.state.user.userInfo
		},
	},
  created() {
    window.addEventListener('scroll', this.Scrollbottom);  //页面加载时监听滚动事件
  },
  destroyed() {
    window.removeEventListener('scroll', this.Scrollbottom) //页面离开后销毁监听事件
  },
  async mounted () {
    await this.$onLaunched;

    this.DEBUG=process.env.VUE_APP_DEBUG
      console.log(this.$route.query)

    if(this.$route.query.address!=undefined){
      this.$store.commit('user/SET_WALLET_ADDRESS', this.$route.query.address)
    }
    this.init()
    this.getApprove()
    this.getNotice()
  },
  beforeDestroy(){
    if(this.thread>0){
      clearInterval(this.thread)
    }
  },
  methods: {
    getNotice() {
      this.get('/api/Community/announcement', {page:1}, 'POST').then(res => {
        this.notice = res.list.data[0]
        var show_time = new Date(this.notice.create_time).getTime()+(1000*60*60*24*7)
        var now_time = new Date().getTime()
        if(show_time>now_time){
          this.show_notice = true
        }
      })
    },
    dd(){
        this.loading =Loading.service({
            lock: true,
            text: '交易正在处理中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
    },
    async updataLevel(){
      const that=this
      this.sendWeb3(walletHelper.getContract('dao').methods.updateLevel(walletHelper.getAddress())).then((result)=>{
        console.log(result)
        that.init()
        that.$toast('升级成功')
        that.addPop2=false
      })
    },

    async updataRanking(){
      const that=this
      this.sendWeb3(walletHelper.getContract('daoV1h').methods.checkRanking(walletHelper.getAddress())).then((result)=>{
        console.log(result)
        that.init()
        that.$toast('PK成功')
        that.addPop4=false
      })
    },
    indexMethod(index) {
      return index +1;
    },
    async checkTrends(){
      const that=this
      this.sendWeb3(walletHelper.getContract('dao390').methods.checkTrends(walletHelper.getAddress())).then((result)=>{
        console.log(result)
        that.init()
        that.$toast('成功')
        that.addPop7=false
      })
    },
    async changeTimeClick(){
      const that=this
      this.sendWeb3(walletHelper.getContract('daoV4').methods.settlementOld(walletHelper.getContractAddress('rel'),walletHelper.getAddress(),this.changeTime)).then((result)=>{
        console.log(result)
        that.init()
        that.$toast('更正成功')
        that.addPop6=false
      })
    },
    init() {
      const that=this
      this.mt_addressToken=walletHelper.getContractAddress('mt')
      this.ulm_addressToken=walletHelper.getContractAddress('ulm')

      walletHelper.getContract('query').methods.getTokenToUsdt(walletHelper.getContractAddress('mt'),walletHelper.toWei("1")).call().then((result)=>{
        this.mt_price=walletHelper.Wei(result)
      })
      walletHelper.getContract('query').methods.getTokenToUsdt(walletHelper.getContractAddress('ulm'),walletHelper.toWei("1")).call().then((result)=>{
        this.ulm_price=walletHelper.Wei(result)
      })
      walletHelper.getUsdt().methods.balanceOf(walletHelper.getAddress()).call().then(( result)=>{
        this.balance.usdt = walletHelper.Wei(result)
      })
      walletHelper.getContract('mt').methods.balanceOf(walletHelper.getAddress()).call().then((result)=>{
        this.balance.mt = walletHelper.Wei(result)
      })
      walletHelper.getContract('ulm').methods.balanceOf(walletHelper.getAddress()).call().then((result)=>{
        this.balance.ulm = walletHelper.Wei(result)
      })
      // walletHelper.getContract('daoV1').methods.getAllPower().call().then(res=>{
      //     console.log(res)
      //     that.allPowerArr=res
      //     that.$set(that.info,'allPower',Math.ceil(walletHelper.Wei(res[0])))
      // })
      walletHelper.getUserData(walletHelper.getAddress(),(res,e)=>{
        console.log(56655,res)
        if(res){
          that.user=res
          let time=Math.ceil(new Date().getTime()/1000)
          if(res._userTrendsPower_390>0){
            walletHelper.getContract('dao390').methods.getUserProfitTrends(walletHelper.getAddress(),time).call().then(res=>{
              console.log(res)
              that.$set(that.info,'UserProfitTrends',walletHelper.Wei(res))
              that.$set(that.info,'takeTrends',Number(walletHelper.Wei(res)))
              if(this.info.takeTrends>0){
                walletHelper.getContract('dao390').methods.getProfitLength().call().then(res=>{
                  walletHelper.getContract('dao390').methods.getProfit(String(Number(res)-1)).call().then(res=>{
                    console.log(res)
                    that.$set(that.info,'takeTrendsCount',this.toFixedWei(this.info.takeTrends*walletHelper.Wei(res[1])/3.9,6))

                  })
                })
              }
            })
          }
          if(res._minerCount_390>0){
            walletHelper.getContract('dao390').methods.getUserPower(walletHelper.getAddress(),time).call().then(res1=>{
              console.log(res1)

              that.$set(that.info,'power390',walletHelper.Wei(res1))
              if(res._userTrendsTime_390>0&&that.info.power390<res._userTrendsPower_390){
                that.addPop7=true
                return
              }
              if(res.userLevel>0){
                walletHelper.getContract('dao390').methods.getRate(walletHelper.getAddress(),res1).call().then(res=>{
                  that.$set(that.info,'RecommendedValue',res[0])
                })
              }else if(res1>0){
                that.$set(that.info,'RecommendedValue',20)
              }
            })
            walletHelper.getContract('dao390').methods.getUserProfit(walletHelper.getAddress(),time).call().then(res=>{
                console.log(res)
                that.$set(that.info,'UserProfit',walletHelper.Wei(res))
                that.$set(that.info,'takeUserProfit',walletHelper.Wei(walletHelper.add(res,that.user._userSurplus_390)))

            })

          }

          walletHelper.getContract('daoV1h').methods.getTeamPower(walletHelper.getAddress()).call().then(res=>{
            console.log(res)
            that.$set(that.info,'powerTeam',res)

          })
          walletHelper.getContract('daoV1').methods.indexFromTime(time).call().then(index=>{
            console.log('new_index',index)
            that.$set(that.info,'new_index',index)
            // walletHelper.getContract('dao390','0xf487AaAfEc20Ed120C9162C96F40494b4d65c212').methods.getData(walletHelper.getAddress(),10000,100).call().then(res=>{
            walletHelper.getContract('daoV1h').methods.getData(walletHelper.getAddress(),10000,500).call().then(res=>{
              if(res[0]>0){
                // console.log('111111111',res)
                this.minerList=[]
                for(let i=0;i<res[0];i++){
                  that.minerList.push({start:index-Number(res[i*10+1]),num:Number(res[i*10+2])})
                }
                // console.log(that.minerList)
              }

            })

          })


          // if(res.power>0&&that.thread==0){
          //   that.thread=setInterval(()=>{
          //     walletHelper.getContract('daoV4').methods.getPendingProfit(walletHelper.getAddress()).call().then(res=>{
          //         // console.log(res)
          //         that.pending=res
          //     })
          //   },3*1000)
          // }
          
          // if(Number(res.userLastTime)>0&&Number(res.userLastTime)<this.changeTime){
          //   that.addPop6=true
          //   return
          // }

          that.level=res.userLevel
          walletHelper.getContract('daoV4').methods.getLevel(walletHelper.getContractAddress('rel'),walletHelper.getAddress()).call().then(level=>{
            if(that.level!=level){
              that.addPop2=true
            }else if(Number(that.level)>1){
              walletHelper.getContract('daoV1').methods._pool_info_v(walletHelper.getAddress(),2).call().then(res=>{
                if(Number(res)==0){
                  walletHelper.getContract('daoV1h').methods.isRanking(walletHelper.getAddress()).call().then(res=>{
                    if(Number(res)>0){
                      that.addPop4=true
                    }
                  })
                }

              })
            }
          })


         
        }else{
          console.log('getUserData错误  error',e)
        }
      })

      walletHelper.getContract('daoV1').methods._allPower('1').call().then(res=>{
          console.log(res)
          that.$set(that.info,'allTeamPower',res)
      })

      // walletHelper.getContract('daoV1').methods.getMinerMoney(walletHelper.getAddress()).call({form:walletHelper.getAddress()}).then(res=>{
      //     console.log(res,'111111111',walletHelper.getAddress())
      //     that.$set(that.info,'minerMoneyMt',walletHelper.Wei(res[0]))
      //     that.$set(that.info,'minerMoneyUlm',walletHelper.Wei(res[1]))
      //     that.$set(that.info,'minerMoneyUsdt',walletHelper.Wei(res[2]))
      // })


    //  this.estimate()

     


    },
    async openLevelInfo(){
      const that=this
      try{
        console.log(walletHelper.getAddress())
               var result=await walletHelper.getContract('daoV1').methods.getChildMintCount(walletHelper.getAddress()).call()
               that.$set(that.info,'childCount',result)
               if(that.level==1){
                  that.NFT=await walletHelper.getContract('rel').methods.getPressureNftTokenId(walletHelper.getAddress()).call()
               }
        
      }catch(e){
        console.log(1111122222222,e)
      }

        
        that.addPop3=true
    },
    estimate(){
      const that=this
      let time=Math.ceil(new Date().getTime()/1000)
      console.log('555555555',time)
      let name=['静态','动态','V2','V3']
      walletHelper.getContract('daoV1').methods.startTime().call().then(startTime=>{
        console.log('startTime',new Date(startTime+'000'))
        walletHelper.getContract('daoV1').methods.indexFromTime(String(time)).call().then(index=>{
          let addTime=0
          let start=new Date(Number(startTime+'000')+addTime*86400000)
            console.log('555555555',index,that.getTimestamp(start))
            for(let i=0;i<4;i++)
            walletHelper.getContract('daoV4').methods.getDayProfit(walletHelper.getAddress(),String(Number(index)+addTime),'86400',String(i),[0,0,0,0]).call().then(res=>{
              console.log(name[i],walletHelper.Wei(res.ulmStatic))
            })
        })
      })
    },
    getTake(type){
      const that=this
      if(walletHelper.isNotTranfer()){
        return
      }
      let count=type==1?this.info.takeUserProfit:this.info.takeTrendsCount
      if(this.mt_price<40&&Number(this.balance.mt)<Number(count)){
        this.$toast('余额不足'+count+'MT,无法提取')
        return
      }
      if(this.mt_price<120){
        this.take_limit_t=100
        this.take_limit=100
      }

      that.addPop=type

    },

    fromWei(wei){
      return wei?walletHelper.Wei(wei):0
    },
    takePending(){
      
      const that=this
      if(walletHelper.isNotTranfer()){
        return
      }

      let count1=this.addPop==1?this.info.takeUserProfit:this.info.takeTrendsCount

      if(count1>0){
        let limit=that.addPop==1?this.take_limit:this.take_limit_t
        console.log(count1,Number(this.balance.mt)/limit*100)
        let num=that.level?1:2
        if(Number(count1)+num>Number(this.balance.mt)/limit*100){
          this.$toast('MT余额不足'+(Number(count1)+num))
          return
        }
        this.sendWeb3(walletHelper.getContract('dao390').methods[that.addPop==1?'takeMiner':'takeTrends']()).then((result)=>{
          console.log(result)
          that.init()
          that.$toast('提取成功')
          that.addPop=0
        })
      }else{
        this.$toast('无可领取')
      }
      
    },
    Scrollbottom() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        this.next_pages()
      }
    },
    next_pages(){
      if(this.tableData!=undefined&&this.tableData.length>0){
				this.current_page++
				if (this.last_page<this.current_page) {
					return
				}
				this.init()
			}
    },
    getApprove(){
      const that=this
      console.log(walletHelper.getAddress(),111,walletHelper.getContractAddress('dao390'))
      walletHelper.getContract('mt').methods.allowance(walletHelper.getAddress(),walletHelper.getContractAddress('dao390')).call().then(res=>{
          console.log(res)
          that.approve_mt=walletHelper.Wei(res)
      })
      walletHelper.getContract('ulm').methods.allowance(walletHelper.getAddress(),walletHelper.getContractAddress('dao390')).call().then(res=>{
          console.log(res)
          that.approve_ulm=walletHelper.Wei(res)
      })
      // walletHelper.getUsdt().methods.allowance(walletHelper.getAddress(),walletHelper.getContractAddress('dao')).call().then(res=>{
      //     console.log('allowance',walletHelper.Wei(res))
      //     that.approve_usdt=walletHelper.Wei(res)
      // })
      walletHelper.getContract('ulm').methods.balanceOf(walletHelper.getAddress()).call().then(res=>{
          console.log(res)
          that.blance_ulm=res
      })
    },
    async approveMt(){
      const that=this
      that.$toast('正在处理中')
      this.sendWeb3(walletHelper.getContract('mt').methods.approve(walletHelper.getContractAddress('dao390'),walletHelper.toWei('9999999999999999'))).then((result)=>{
        console.log(result)
        that.getApprove()
        that.$toast('MT授权成功')
      })
    },
    async approveUlm(){
      const that=this
      that.$toast('正在处理中')
      this.sendWeb3(walletHelper.getContract('ulm').methods.approve(walletHelper.getContractAddress('dao390'),walletHelper.toWei('9999999999999999'))).then((result)=>{
        console.log(result)
        that.getApprove()
        that.$toast('ULME授权成功')
      })
    },
    async approveUsdt(){
      const that=this
      that.$toast('正在处理中')
      this.sendWeb3(walletHelper.getUsdt().methods.approve(walletHelper.getContractAddress('dao'),walletHelper.toWei(String(330*this.buyNumber)))).then(result=>{
        console.log(result)
        that.getApprove()
        that.$toast('USDT授权成功')
      })
    },
    addLpFun1(){
      if(this.buyNumber<=0){
        this.$toast('请输入数量')
        return
      }
      const form={
        wallet:walletHelper.getAddress()
      }
      if(this.isSetting){
        if(!walletHelper.getWeb3().utils.isAddress(this.buyAccount)){
          this.$toast('请输入地址')
          return
        }
        form.toAddress=this.buyAccount
      }
      this.get('/api/User/get_parent', form, 'POST').then(res => {
        console.log(res)
        this.miner(res.info)
      })
    },
    async miner(parents){
      const that=this

      if(walletHelper.isNotTranfer()){
        return
      }
      let obj=walletHelper.getContract('dao390').methods.becomeMiner(parents,this.buyNumber)
      if(this.isSetting&&walletHelper.getWeb3().utils.isAddress(this.buyAccount)){
        obj=walletHelper.getContract('dao390').methods.becomeMiner(this.buyAccount,parents,this.buyNumber)
      }
      this.sendWeb3(obj).then(result=>{
          console.log(result)
          that.addPop1=false
        that.$toast('添加成功')
        this.init()
      },e=>{
        console.log(11111111111,e)
      })
      // const gas = await walletHelper.getContract('dao').methods.becomeMiner(parents,this.buyNumber)
      // .estimateGas({ from: walletHelper.getAddress()})
      // .catch(async e => {
      //     this.errorToast(e)
      //     throw e;
      //   });
      // console.log(gas)
      // this.dd()
      // walletHelper.getContract('dao').methods.becomeMiner(parents,this.buyNumber).send({from:walletHelper.getAddress(),gas:gas}).then((result)=>{
      //     console.log(result)
      //     that.addPop1=false
      //   that.closeLoading('添加成功')
      //   this.init()
      // },error=>{
      //   console.log(error)
      //   that.closeLoading('添加失败');
      // })
    },
    closeLoading(msg){
      if(msg&&msg!=''){
        this.$toast({
            title: msg
        });
      }
      this.loading&&this.loading.close();
    },
    copy(text){
      this.$copyText(text).then(e=>{
        this.$toast(this.$t('tips.title2'))
      }, e=> {
        this.$toast(this.$t('tips.title3'))
      })
    },
    openUrl(){
      if(walletHelper.getChainId()==97){
        window.open('http://fishapi.h5zn.cn/#/swap')
      }else{
        window.open('https://pancakeswap.finance/swap')
      }
    }
  },
}
</script>
 
<style lang="scss" scoped>
  .pledge_num{
    width:20px;
    height:20px;
    border-radius:50%;
    border: 2px solid #ffffff;
    color: #ffffff;
    font-size: 0.8rem;
  }
  .bgImg{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: -1;
  }
  .toPay{
    font-size: 0.8rem;
    font-family: SimHei;
    color: #00E7D5;
    border-radius: 50px;
    border: 1px solid #00E7D5;
    padding: 3px 5px;
    img{
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
  .nav{
    width: 48%;
    background: #041834;
    color: #737373;
    height: 50px;
    border-radius: 5px;
    margin: 10px 0;
    font-size: 1rem;
  }
  .LP_title{
    color: #00FFEA;
    font-size: 1rem;
    padding: 10px 0;
  }
  .LP_text{
    color: #ffffff;
    font-size: 0.85rem;
    padding: 5px 0;
  }
  .LP_num{
    color: #00FFEA;
    margin-left: 10px;
  }
  .LP_box0{
    width: 100%;
    background: rgba(12,35,129,0.3);
    border-radius:5px;
    padding:10px;
    margin-bottom: 10px;
  }
  .LP_box1{
    width: 100%;
    background: #1332B7;
    border-radius: 8px;
    padding: 10px 15px;
    margin-bottom: 10px;
    .LP_title{
      color: #ffffff;
    }
  }
  .LP_box2{
    width: 100%;
    background: #041453;
    border-radius: 8px;
    padding: 10px 13px;
    margin-bottom: 10px;
  }
  .LP_num1{
    text-align: right;
    font-size: 0.8rem;
    color: #ffffff;
  }
  .LP_num2{
    text-align: right;
    font-size: 0.75rem;
    color: #00FFEA;
  }
  .LP_btn{
    width: 100%;
    text-align: center;
    background: #1332B7;
    border-radius: 5px;
    color: #ffffff;
    font-size: 1rem;
    padding: 15px;
    margin: 20px 0;
    white-space: nowrap;
  }
  .LP_btnh{
    width: 100%;
    text-align: center;
    background: #990000;
    border-radius: 5px;
    color: #ffffff;
    font-size: 1rem;
    padding: 15px;
    margin: 20px 0;
    white-space: nowrap;
  }
  .LP_btn2{
    text-align: center;
    background: #00FFEA;
    border-radius: 5px;
    color: #000000;
    font-size: 0.9rem;
    padding: 5px 25px;
    white-space: nowrap;
  }
  .LP_btn_after{
    text-align: center;
    background: #f0f0f0;
    border-radius: 5px;
    color: #999999;
    font-size: 0.9rem;
    padding: 5px 25px;
    white-space: nowrap;
  }
  .date_show{
    position: relative;
    z-index: 4;
    height: 20px;
    font-size: 0.75rem;
    color: #ffffff;
    background: #0c3774;
    border-radius: 5px;
    padding: 0 8px;
    i{
      font-size: 0.75rem;
      color: #1870EA;
      transform: scale(0.7);
      margin-left: 10px;
    }
  }
  .date_list{
    position: absolute;
    z-index: 3;
    right: 0;
    left: 0;
    top: 0;
    margin: auto;
    font-size: 0.75rem;
    color: #ffffff;
    background: #0c3774;
    border-radius: 5px;
    padding: 20px 8px 0;
    .date_item{
      padding: 3px 0;
    }
  }
  .address{
    width: 0;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .take{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1000;
    font-family: PingFang SC;
    .take_bg{
      background: #ffffff;
      padding: 6px;
      border-radius: 7px;
      position: relative;
      z-index: 2;
      width: 90%;
      .take_box{
        // background: rgba(#000000,0.7);
        z-index: 1000;
        padding: 15px 10px;
        border-radius: 7px;
        font-size: 16px;
        .take_content{
          font-size: 0.9rem;
          margin: 10px 0;
          span{
            color: #ff0000;
          }
        }
        .take_text{
          color: #999999;
          font-size: 0.8rem;
          margin: 10px 0;
          span{
            color: #ff0000;
          }
        }
        .take_btn1{
          width: 45%;
          padding: 5px 0;
          background: #21C7D5;
          border-radius: 32px;
          color: #ffffff;
          margin-top: 10px;
        }
        .take_btn2{
          width: 45%;
          padding: 5px 0;
          border: 1px solid #999999;
          border-radius: 32px;
          margin-top: 10px;
        }
      }
    }
  }
  .bind{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1000;
    font-family: PingFang SC;
    .bind_bg{
      background: #21C7D5;
      padding: 6px;
      border-radius: 7px;
      position: relative;
      z-index: 2;
      width: 90%;
      .bind_box{
        // background: rgba(#000000,0.7);
        z-index: 1000;
        padding: 15px 10px;
        border-radius: 7px;
        font-size: 16px;
        .bind_text{
          color: #CE0F0F;
          font-size: 14px;
          padding: 0 5px;
          margin-bottom: 5px;
        }
        .bind_text1{
          color: rgb(14, 245, 137);
          font-size: 14px;
          padding: 0 5px;
          margin-bottom: 10px;
        }
        .bind_text2{
          color: rgb(233, 99, 22);
          font-size: 14px;
          padding: 0 5px;
          margin-bottom: 10px;
        }
        input{
          font-size: 16px;
          // width: 0;
          // flex: 1;
          width: 100%;
          padding: 15px 15px;
          background: #ffffff;
          border-radius: 3px;
          // min-height: 100px;
          color: #00DEFF;
          line-height: 18px;
          margin: 15px 0 10px;
        }
        // chrome
        input::-webkit-input-placeholder { /* WebKit browsers */
          color: #999999;
          font-size:15px;
        }
        // firefox
        input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
          color: #999999;
          font-size:15px;
        }
        button{
          background: #ffffff;
          color: #177C85;
          font-size: 14px;
          border: none;
          // margin-left: 10px;
          border-radius: 3px;
          height: 40px;
          // width: 80px;
          width: 100%;
        }
      }
    }
  }

  .pledge{
  .pledge_li{
    position: absolute;
    top:-20px;
    transform: scale(0.8) translateX(-50%);
    width:30px;
    color: #ffffff;
  }
  .pledge_box{
    width: 100%;
    background: #0C2379;
    border-radius: 5px;
    padding: 0 10px;
    color: #fff;
  }
  .pledge_box2{
    width: 100%;
    background: #041453;
    border-radius: 5px;
    padding: 0 10px;
    color: #fff;
  }
  .pledge_title{
    font-size: 0.8rem;
    line-height: 1.5rem;
  }
  .pledge_text{
    font-size: 0.8rem;
    line-height: 1.5rem;
    color: #00FFEA;
  }
  .pledge_open{
    border-radius: 50%;
    border: 1px solid #00FFEA;
    color: #00FFEA;
    font-size: 0.7rem;
    width: 1rem;
    height: 1rem;
    line-height: 1rem;
    text-align: center;
  }
  .pledge_top{
    border-bottom: 1px solid #0B2FB8;
    padding: 15px 0;
    .pledge_day{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      transform: scale(0.8);
    }
  }
  .pledge_jdt{
    width: 100%;
    height: 20px;
    background: #90acaf;
    border-radius: 50px;
    overflow: hidden;
    p{
      height: 100%;
      border-radius: 50px;
      background: rgb(19, 183, 23);
    }
  }
  .pledge_input{
    background: none;
    color: #fff;
    width: 100%;
  }
  .pledge_input::-webkit-input-placeholder{color:#839CFF;}
  .pledge_input::-moz-placeholder{color:#839CFF;}
  .pledge_input:-ms-placeholder{color:#839CFF;}
  .pledge_btn{
    width: 47%;
    padding: 5px 0;
    color: #000;
    background: #00FFEA;
    border-radius: 5px;
    font-size: 0.7rem;
    margin-bottom: 10px;
  }
}
.shifang{
    background-color: #3a952a;
    text-align: center;
    background: #1332B7;
    border-radius: 5px;
    color: #ffffff;
    font-size: 1rem;
    padding: 5px;
    margin: 5px;
    white-space: nowrap;
}
.shifang1{
    background-color: #bb5a82;;
}
</style>